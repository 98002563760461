.App_login {
  text-align: center;
  font-family: "Hanuman", serif;
  font-style: normal;
}
.media_btn{
    display: none;
}
@media (max-width: 1200px) {

  .text-area_nav {
    font-size: 20px;
  }
  .text-area_list-item {
    font-size: 18px;
  }
  .header-pal {
    font-size: 24px;

  }
}
@media (max-width: 800px) {
.media_btn {
    display: block;
    position: absolute;
    top: 2px;
    left: 4px;
    border: none;
    background: unset;
    z-index: 9999999;
}
.media_btn svg {
    width: 48px;
    height: 48px;
    color: red;
    transform: rotateZ(180deg);
}
.musik_btn-box {
    right: 2px;
}
  .text-area {
    display: none;
  }
  .header-pal {
    width: 90%;
  }
  .navigate__item {
    font-size: 14px;
  }
  .input__style {
    font-size: 12px;
  }
  .input__style label {
    font-size: 10px;
  }
   .input__style .print-btn_wz {
       display: none;
   }
.add_table {
    display: none;
}
  .add_table button {
    font-size: 8px;
  }
  .add_table input{
      font-size: 12px;
  }
  .search {
      font-size: 12px;
  }
 .info {
    display: none;
 }

.work__nav-item {
    display: none;
}
.work__place {
    width: 100%;
}
.work__place .table__style .input__style {
    flex-direction: column;
    align-items: center;
    width: 100%;
}
.timer_to-apgrate {
    font-size: 12px;
    width: 170px;
}
.palety {
    display: none;
}
.work__nav{
    width: 44%;
    position: absolute;
    left: -44%;

}
.work__nav-active{
    width: 44%;
    position: absolute;
    left: 0;
    z-index: 999999;
    background: white;
    padding-left: 12px;
}
.work__place .table__style .table_margin {
    padding-top: 78px;
}
  .work__manager_list button {
    font-size: 8px;
  }
   tr .hide-on-print {
      display: flex;
      width: 100%;
      
  }
  .table tr td .table_btn {
        margin: 0 1px;
        max-height: 16px;
        max-width: 18px;
  }
  .table tr:hover td {
      background-color: #e9ecef;
      font-size: 8px;
  }
  .table_btn-copy button {
    height: 28px;
    width: 28px;
  }
  .table_btn-copy button svg {
    height: 12px;
    width: 12px;
  }
  .work__item {
    margin: 0;
  }
  .table tr {
    font-size: 7px;
  }
  .palety {
    font-size: 18px;
  }
  .table tr td {
    font-size: 8px;
  }
  li .active {
    background-color: yellow;
    text-decoration: underline;
    max-height: 16px;
  }

  .serch-chekbox {
    font-size: 12px;
  }
  .table__item {
    font-size: 12px;
  }
}
